import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Divider
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';
import { simpleMessage } from '../../../helpers/Helpers';
import { getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { getCobroDiarioReportAsync } from '../../../api/Report';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import { getGestoresAsync } from '../../../api/Users';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';

const CobroDiario = () => {
	const [data, setData] = useState([]);

	const compRef = useRef();
	const { selectedGestor, selectedFecha } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const [gestorName, setGestorName] = useState('');
	const [gestorList, setGestorList] = useState([]);

	const token = getToken();
	const user = getUser();

	useEffect(() => {
		(async () => {
			let gestores = await getGestores();

			if (selectedGestor === 't') {
				setGestorName('Todos');
			} else {
				let name = gestores.filter((item) => item.id.toString() === selectedGestor);
				setGestorName(name[0].fullName);
			}

			const data = {
				fecha: selectedFecha.toString(),
				gestorId: selectedGestor === 't' ? 0 : selectedGestor
			};

			setIsLoading(true);
			const result = await getCobroDiarioReportAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);
		})();
	}, []);

	const getGestores = async () => {
		setIsLoading(true);
		const result = await getGestoresAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);

		setGestorList(
			selectedGestor === 't'
				? result.data.result
				: result.data.result.filter((g) => g.id.toString() === selectedGestor)
		);
		return result.data.result;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 4mm; }
`;

	const exportExcel = () => {
		let exp = [];

		data.map((item) => {
			const dataGestor = gestorList.filter((gestor) => gestor.id === item.gestor);
			let gestor = dataGestor[0];
			let obj = {
				cliente: item.fullName,
				direccion: item.address,
				telefono: item.phone,
				fechaEntrega: moment(item.fechaEntrega).format('L'),
				fechaVencimiento: moment(item.fechaVencimiento).format('L'),
				tipo: item.tipo,
				cuota: item.cuota,
				atraso: item.atraso,
				atrasoDias: item.atrasoDias,
				cuotaMasAtraso: item.cuota + item.atraso,
				saldoTotal: item.saldoTotal,
				gestor: gestor.fullName
			};

			exp.push(obj);
		});
		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'cobroDiario');
		XLSX.writeFile(bk, 'CobroDiario.xlsx');
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Reporte de Cobro Diario
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton
										variant="outlined"
										// style={{ position: "fixed", right: 20, top: 75 }}
									>
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Fecha de Cobro: ${moment(selectedFecha).format('L')}`}
								</Typography>
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							{gestorList.map((gestor) => {
								const cGestor = data.filter((c) => c.gestor === gestor.id);
								let totalSaldo = 0;

								cGestor.map((c) => {
									totalSaldo += c.saldoTotal;
								});

								const cGestorD = cGestor.filter((c) => c.tipo === 'D');
								let sumaDia = 0;
								let sumaSaldoDia = 0;
								cGestorD.map((c) => {
									sumaDia += c.cuota;
									sumaSaldoDia += c.saldoTotal;
								});

								const cGestorM = cGestor.filter((c) => c.tipo === 'M');
								let sumaMora = 0;
								let sumaSaldMora = 0;
								cGestorM.map((c) => {
									sumaMora += c.cuota + c.atraso;
									sumaSaldMora += c.saldoTotal;
								});

								const cGestorV = cGestor.filter((c) => c.tipo === 'V');
								let sumaVencido = 0;
								let sumaSaldoVencido = 0;
								cGestorV.map((c) => {
									sumaVencido += c.cuota + c.atraso;
									sumaSaldoVencido += c.saldoTotal;
								});

								return (
									<Stack key={gestor.id}>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'left'
												}}
												fontSize={11}
											>
												Gestor:
											</Typography>
											<Typography
												sx={{
													textAlign: 'left'
												}}
												fontSize={11}
											>
												{gestor.fullName}
											</Typography>
										</Stack>
										<Divider />
										{isEmpty(cGestor) ? (
											<></>
										) : (
											<TableContainer>
												<Table aria-label="simple table" size="small">
													<TableHead>
														<TableRow>
															<TableCell
																align="left"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																Cliente
															</TableCell>

															<TableCell
																align="left"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																Dirección
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																Teléfono
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																F.Entrega
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																F.Vencimiento
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																Tipo
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																Cuota
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																Atraso
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																A.Dias
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																C + A
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 9 }}
															>
																Saldo Total
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{cGestorD.map((row) => {
															return (
																<TableRow key={row.id}>
																	<TableCell
																		align="left"
																		component="th"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.fullName}
																	</TableCell>
																	<TableCell
																		align="left"
																		component="th"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.address}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.phone}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaEntrega
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaVencimiento
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.tipo}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.cuota)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.atraso)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.atrasoDias}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(
																			row.atraso + row.cuota
																		)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.saldoTotal)}
																	</TableCell>
																</TableRow>
															);
														})}
														{cGestorD.length === 0 ? (
															<></>
														) : (
															<TableRow key={-1}>
																<TableCell
																	align="center"
																	colSpan={11}
																>
																	<Stack
																		direction="row"
																		flex="row"
																		justifyContent="space-around"
																	>
																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Créditos del Día
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{cGestorD.length}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Monto a Recuperar al
																				Día
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(sumaDia)}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Porcentaje Dia
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{`${(
																					(sumaDia /
																						totalSaldo) *
																					100
																				).toFixed(2)} %`}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Saldo Creditos al
																				Día
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					sumaSaldoDia
																				)}
																			</Typography>
																		</Stack>
																	</Stack>
																</TableCell>
															</TableRow>
														)}

														{cGestorM.map((row) => {
															return (
																<TableRow key={row.id}>
																	<TableCell
																		align="left"
																		component="th"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.fullName}
																	</TableCell>
																	<TableCell
																		align="left"
																		component="th"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.address}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.phone}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaEntrega
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaVencimiento
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.tipo}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.cuota)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.atraso)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.atrasoDias}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(
																			row.atraso + row.cuota
																		)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.saldoTotal)}
																	</TableCell>
																</TableRow>
															);
														})}
														{cGestorM.length === 0 ? (
															<></>
														) : (
															<TableRow key={-2}>
																<TableCell
																	align="center"
																	colSpan={11}
																>
																	<Stack
																		direction="row"
																		flex="row"
																		justifyContent="space-around"
																	>
																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Créditos en Atraso
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{cGestorM.length}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Monto a Recuperar en
																				Atraso
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(sumaMora)}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Porcentaje Mora
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{`${(
																					(sumaMora /
																						totalSaldo) *
																					100
																				).toFixed(2)} %`}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Saldo Creditos en
																				Mora
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					sumaSaldMora
																				)}
																			</Typography>
																		</Stack>
																	</Stack>
																</TableCell>
															</TableRow>
														)}

														{cGestorV.map((row) => {
															return (
																<TableRow key={row.id}>
																	<TableCell
																		align="left"
																		component="th"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.fullName}
																	</TableCell>
																	<TableCell
																		align="left"
																		component="th"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.address}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.phone}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaEntrega
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaVencimiento
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.tipo}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.cuota)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.atraso)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.atrasoDias}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(
																			row.atraso + row.cuota
																		)}
																	</TableCell>
																	<TableCell
																		align="center"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.saldoTotal)}
																	</TableCell>
																</TableRow>
															);
														})}
														{cGestorV.length === 0 ? (
															<></>
														) : (
															<TableRow key={-3}>
																<TableCell
																	align="center"
																	colSpan={11}
																>
																	<Stack
																		direction="row"
																		flex="row"
																		justifyContent="space-around"
																	>
																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Créditos Vencidos
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{cGestorV.length}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Monto a Recuperar en
																				Vencido
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					sumaVencido
																				)}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Porcentaje Vencidos
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{`${(
																					(sumaVencido /
																						totalSaldo) *
																					100
																				).toFixed(2)} %`}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Saldo Creditos
																				Vencidos
																			</Typography>
																			<Typography
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					sumaSaldoVencido
																				)}
																			</Typography>
																		</Stack>
																	</Stack>
																</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</TableContainer>
										)}
									</Stack>
								);
							})}
						</>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Reporte de Cobro Diario'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={10}
								>
									Parametros:
								</Typography>
								<Typography fontSize={10} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>

								<Typography fontSize={10} textAlign="left">
									{`Fecha de Cobro: ${moment(selectedFecha).format('L')}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={10}
								>
									Usuario:
								</Typography>
								<Typography fontSize={10} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={10} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<hr />
						{isEmpty(data) ? (
							<NoData />
						) : (
							<>
								{gestorList.map((gestor) => {
									const cGestor = data.filter((c) => c.gestor === gestor.id);
									let totalSaldo = 0;

									cGestor.map((c) => {
										totalSaldo += c.saldoTotal;
									});

									const cGestorD = cGestor.filter((c) => c.tipo === 'D');
									let sumaDia = 0;
									let sumaSaldoDia = 0;
									cGestorD.map((c) => {
										sumaDia += c.cuota;
										sumaSaldoDia += c.saldoTotal;
									});

									const cGestorM = cGestor.filter((c) => c.tipo === 'M');
									let sumaMora = 0;
									let sumaSaldMora = 0;
									cGestorM.map((c) => {
										sumaMora += c.cuota + c.atraso;
										sumaSaldMora += c.saldoTotal;
									});

									const cGestorV = cGestor.filter((c) => c.tipo === 'V');
									let sumaVencido = 0;
									let sumaSaldoVencido = 0;
									cGestorV.map((c) => {
										sumaVencido += c.cuota + c.atraso;
										sumaSaldoVencido += c.saldoTotal;
									});

									return (
										<Stack key={gestor.id}>
											<Stack direction="row" spacing={1}>
												<Typography
													sx={{
														color: '#2196f3',
														textAlign: 'left'
													}}
													fontSize={10}
												>
													Gestor:
												</Typography>
												<Typography
													sx={{
														textAlign: 'left'
													}}
													fontSize={10}
												>
													{gestor.fullName}
												</Typography>
											</Stack>

											<TableContainer>
												<Table aria-label="simple table" size="small">
													<TableHead>
														<TableRow>
															<TableCell
																align="left"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																Cliente
															</TableCell>

															<TableCell
																align="left"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																Dirección
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																Teléfono
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																F.Entrega
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																F.Vencimiento
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																Tipo
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																Cuota
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																Atraso
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																A.Dias
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																C + A
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																style={{ fontSize: 8 }}
															>
																Saldo T.
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{cGestorD.map((row) => {
															return (
																<TableRow key={row.id}>
																	<TableCell
																		align="left"
																		component="th"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																		className="text-dark"
																	>
																		{row.fullName}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.address}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.phone}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaEntrega
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaVencimiento
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.tipo}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.cuota)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.atraso)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.atrasoDias}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(
																			row.atraso + row.cuota
																		)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.saldoTotal)}
																	</TableCell>
																</TableRow>
															);
														})}
														{cGestorD.length === 0 ? (
															<></>
														) : (
															<TableRow key={-1}>
																<TableCell
																	align="center"
																	colSpan={11}
																>
																	<Stack
																		direction="row"
																		flex="row"
																		justifyContent="space-around"
																	>
																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Créditos del Día
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{cGestorD.length}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Monto a Recuperar al
																				Día
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(sumaDia)}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Porcentaje Dia
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{`${(
																					(sumaDia /
																						totalSaldo) *
																					100
																				).toFixed(2)} %`}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Saldo Creditos al
																				Día
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					sumaSaldoDia
																				)}
																			</Typography>
																		</Stack>
																	</Stack>
																</TableCell>
															</TableRow>
														)}

														{cGestorM.map((row) => {
															return (
																<TableRow key={row.id}>
																	<TableCell
																		align="left"
																		component="th"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																		className="text-dark"
																	>
																		{row.fullName}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.address}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.phone}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaEntrega
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaVencimiento
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.tipo}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.cuota)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.atraso)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.atrasoDias}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(
																			row.atraso + row.cuota
																		)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.saldoTotal)}
																	</TableCell>
																</TableRow>
															);
														})}
														{cGestorM.length === 0 ? (
															<></>
														) : (
															<TableRow key={-2}>
																<TableCell
																	align="center"
																	colSpan={11}
																>
																	<Stack
																		direction="row"
																		flex="row"
																		justifyContent="space-around"
																	>
																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Créditos en Atraso
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{cGestorM.length}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Monto a Recuperar en
																				Atraso
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(sumaMora)}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Porcentaje Mora
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{`${(
																					(sumaMora /
																						totalSaldo) *
																					100
																				).toFixed(2)} %`}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Saldo Creditos en
																				Mora
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					sumaSaldMora
																				)}
																			</Typography>
																		</Stack>
																	</Stack>
																</TableCell>
															</TableRow>
														)}

														{cGestorV.map((row) => {
															return (
																<TableRow key={row.id}>
																	<TableCell
																		align="left"
																		component="th"
																		className="text-dark"
																		scope="row"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.fullName}
																	</TableCell>
																	<TableCell
																		align="left"
																		component="th"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.address}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.phone}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaEntrega
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{moment(
																			row.fechaVencimiento
																		).format('L')}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.tipo}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.cuota)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.atraso)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{row.atrasoDias}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(
																			row.atraso + row.cuota
																		)}
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-dark"
																		style={{
																			fontSize: 8
																		}}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(row.saldoTotal)}
																	</TableCell>
																</TableRow>
															);
														})}
														{cGestorV.length === 0 ? (
															<></>
														) : (
															<TableRow key={-3}>
																<TableCell
																	align="center"
																	colSpan={11}
																>
																	<Stack
																		direction="row"
																		flex="row"
																		justifyContent="space-around"
																	>
																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Créditos Vencidos
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{cGestorV.length}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Monto a Recuperar en
																				Vencido
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					sumaVencido
																				)}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Porcentaje Vencidos
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{`${(
																					(sumaVencido /
																						totalSaldo) *
																					100
																				).toFixed(2)} %`}
																			</Typography>
																		</Stack>

																		<Stack textAlign="center">
																			<Typography
																				style={{
																					fontWeight:
																						'bold',
																					color: '#03a9f4',
																					fontSize: 9
																				}}
																			>
																				Saldo Creditos
																				Vencidos
																			</Typography>
																			<Typography
																				className="text-dark"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					sumaSaldoVencido
																				)}
																			</Typography>
																		</Stack>

																		<div className="page-break" />
																	</Stack>
																</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</TableContainer>
										</Stack>
									);
								})}
							</>
						)}
					</Container>
				</PrintReport>
			</div>
		</div>
	);
};

export default CobroDiario;
